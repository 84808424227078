(function(){
    if(document.querySelector(".gallery .swiper") != null){
        let gallery = new Swiper(document.querySelector(".gallery .swiper"),{
            pagination : {
                el: " .swiper-pagination",
                clickable :true,
            },
            slidesPerView: 1,
            slidesPerGroup: 1
        })
        if(!(gallery.slides.length > 1)){
            document.querySelector(".gallery .swiper-button-prev").style.display = "none";
            document.querySelector(".gallery .swiper-button-next").style.display = "none";
        }
        calcGalleryHeight()
        document.querySelector(".gallery .swiper-button-prev").addEventListener("click",function(){
            gallery.slidePrev();
        })

        document.querySelector(".gallery .swiper-button-next").addEventListener("click",function(){
            gallery.slideNext();
        })
    }
    function calcGalleryHeight(){
        let gallerySwiper = document.querySelector(".gallery .gallery-container .swiper");
        if(gallerySwiper != null){
            let galleryImages = gallerySwiper.querySelectorAll(".gallery-image");
            let maxHeight = 0;
            galleryImages.forEach(v =>{
                if(v.offsetHeight > maxHeight){
                    maxHeight = v.style.paddingBottom;
                }
            })
            gallerySwiper.style.height = maxHeight + "px";
            gallerySwiper.swiper.update();
        }
    }

    let navigator = document.querySelector(".navigator");
    let navigatorTop = navigator != null ? navigator.offsetTop : "";
    let navigatorHeight = navigator != null ? navigator.offsetHeight : "";
    if(window.innerWidth <= 992){
        if(navigator != null){
            navigator.style.display = "none";
        }
    }


    function fixNav(){
        if(navigator != null){
            let top = window.pageYOffset;
            let button = navigator.querySelector(".navigator-button");
            if(top > navigatorTop){
                navigator.style.width = window.innerWidth + "px";
                navigator.classList.add("fixed");
                button.style.opacity = 1;
                button.style.maxHeight = "100px";

            }else{
                navigator.style.width = "100%";
                navigator.classList.remove("fixed");
                button.style.opacity = 0;
                button.style.maxHeight = "0";
            }
        }
    }

    function checkNavigatorLocation(){
        if(document.querySelector(".navigator-tabs ul") != null){
            let top = window.pageYOffset;
            let elements = document.querySelectorAll(".navigator-tabs ul li a");
            let currentEle = null;
            elements.forEach(v=>{
                let sectionId = v.getAttribute("href");
                let ele = document.querySelector(sectionId);
                if(ele.offsetTop <= top + navigatorHeight){
                    if(currentEle == null){
                        v.classList.add("active");
                        currentEle = v;
                    }
                    if(currentEle != null && currentEle != v){
                        currentEle.classList.remove("active");
                        v.classList.add("active");
                        currentEle = v;
                    }
                }else{
                    v.classList.remove("active");
                }
            })
        }
    }

    function showBackToTop(){
        let top = window.pageYOffset;
        let backToTop = document.querySelector(".backtotop button");
        if(backToTop != null){
            if(top > 1000){
                backToTop.style.visibility = "visible";
                backToTop.style.opacity = "1";
            }else{
                backToTop.style.visibility = "hidden";
                backToTop.style.opacity = "0";
            }
            backToTop.addEventListener("click",function(){
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            })
        }
    }

    function waterFall(){
        let propertyItems = document.querySelectorAll(".property-item");
        let totalHeight = [0,0];
        if(propertyItems.length > 0){
            let itemWidth = propertyItems[0].offsetWidth;
            let windowWidth = window.innerWidth;
            propertyItems.forEach((v,i)=>{
                let currentHeight = v.offsetHeight;
                if(windowWidth >= 992){
                    if(i == 0 || i % 2 == 0){
                        v.style.left = 0;
                        v.style.top = totalHeight[0] + "px";
                        totalHeight[0] = totalHeight[0] + currentHeight;
                    }else{
                        v.style.left = itemWidth + "px";
                        v.style.top = totalHeight[1] + "px";
                        totalHeight[1] = totalHeight[1] + currentHeight;
                    }
                }else{
                    v.style.left = 0;
                    v.style.top = totalHeight[0] + "px";
                    totalHeight[0] = totalHeight[0] + currentHeight;
                }
            })
            document.querySelector(".property-content-container").style.height = (totalHeight[0] > totalHeight[1] ?  totalHeight[0] : totalHeight[1]) + "px";
        }
    }

    let spec = document.querySelector(".specification-root");
    if(spec!= null){
        spec.querySelector(".btn-expand-all").addEventListener("click",function(){
            spec.querySelectorAll(".spec-item-container").forEach(v =>{
                v.classList.add("open");
                v.style.height = "auto";
            })

            spec.querySelectorAll(".spec-group>span").forEach(v =>{
                v.classList.remove("is-closed");
                v.classList.add("is-open");
            })
        })

        spec.querySelector(".btn-collapse-all").addEventListener("click",function(){
            spec.querySelectorAll(".spec-item-container").forEach(v =>{
                v.classList.remove("open");
                v.style.height = "0";
            })

            spec.querySelectorAll(".spec-group>span").forEach(v =>{
                v.classList.remove("is-open");
                v.classList.add("is-closed");
            })
        })

        spec.querySelectorAll(".spec-group>span").forEach(v =>{
            v.addEventListener("click",function(e){
                let current = e.currentTarget;
                let isOpen = current.classList.contains("is-open");
                let container = current.parentNode.querySelector(".spec-item-container");
                container.style.height = isOpen ? "0" : "auto";
                container.classList.toggle("open");
                if(isOpen){
                    current.classList.remove("is-open");
                    current.classList.add("is-closed");
                }else{
                    current.classList.remove("is-closed");
                    current.classList.add("is-open");
                }
            })
        })

        spec.querySelectorAll(".spec-group").forEach(v=>{
            if(v.querySelectorAll(".spec-item").length == 0){
                v.style.display = "none";
            }
        })
        let hasSpec = false;
        let hiddenGroup = spec.querySelectorAll(".spec-group");
        for(let i =0;i<hiddenGroup.length;i++){
            if(hiddenGroup[i].style.display != 'none'){
                hasSpec = true;
            }
        }
        if(!hasSpec){
            spec.style.display = "none";
        }
    }



    let accessory = document.querySelector(".accessory-root");
    let accyItems = null;
    if(accessory != null){
        accyItems = accessory.querySelectorAll(".accy-item");
        setupAccy(accessory,accyItems)
    }
    function setupAccy(accessory,accyItems){
        let content = accessory.querySelector(".accy-content-container");
        let more = accessory.querySelector(".accy-more a");
        let windowWidth = window.innerWidth;
        if(accyItems.length > 0){
            content.innerHTML = "";
            if(windowWidth < 425){
                more.style.display = accyItems.length > 1 ? 'inline-block' : 'none';
                accyItems.forEach((v,i)=>{
                    if(i == 0){
                        content.appendChild(v);
                    }
                })
            }else if(windowWidth >= 425 && windowWidth <= 768){
                more.style.display = accyItems.length > 2 ? 'inline-block' : 'none';
                accyItems.forEach((v,i)=>{
                    if(i < 2){
                        content.appendChild(v);
                    }
                })
            }else if(windowWidth > 768 && windowWidth < 992){
                more.style.display = accyItems.length > 1 ? 'inline-block' : 'none';
                accyItems.forEach((v,i)=>{
                    if(i == 0){
                        content.appendChild(v);
                    }
                })
            }else{
                more.style.display = accyItems.length > 4 ? 'inline-block' : 'none';
                accyItems.forEach((v,i)=>{
                    if(i < 4){
                        content.appendChild(v);
                    }
                })
            }
        }

        more.addEventListener("click",function(){
            if(windowWidth < 425){
                accyItems.forEach((v,i)=>{
                    if(i > 0){
                        content.appendChild(v);
                    }
                })
            }else if(windowWidth >= 425 && windowWidth <= 768){
                accyItems.forEach((v,i)=>{
                    if(i >= 2){
                        content.appendChild(v);
                    }
                })
            }else if(windowWidth > 768 && windowWidth < 992){
                accyItems.forEach((v,i)=>{
                    if(i > 0){
                        content.appendChild(v);
                    }
                })
            }else{
                accyItems.forEach((v,i)=>{
                    if(i >= 4){
                        content.appendChild(v);
                    }
                })
            }
            more.style.display = 'none';
        })
    }

    $(function (){
        $('.J_accy-item').on('click mouseenter','.J_accy-item-comment-icon',function(event){
            document.querySelectorAll('[class="J_icon_index"]').forEach(function(element) {
                element.innerHTML = parseInt(element.getAttribute('data-icon-index'))+1;
            });
            $('.J_accy-item-comment').hide();
            $(this).next().fadeIn(200);
        })

        $('.J_accy-item').on('mouseleave','.J_accy-item-comment',function(event){
            $(this).fadeOut(0);
        })


    });


    let listItem = document.querySelectorAll(".list-item");
    if(listItem.length > 0){
        let listButton = document.querySelector(".list-button-container a");
        if(listButton != null){
            listItem.forEach(v=>{
                if(v.classList.contains("status02")){
                    listButton.style.display = "inline-block";
                    return;
                }
            })

            listButton.addEventListener("click",function(e){
                let button = e.currentTarget;
                if(button.classList.contains("show")){
                    button.classList.remove("show");
                    button.classList.add("hide");
                    button.innerText = "显示停产产品";
                    listItem.forEach(v=>{
                        if(v.classList.contains("status02")){
                            v.style.display = "none";
                        }
                        else{
                            v.style.display = "block";
                        }
                    })
                }else{
                    button.classList.remove("hide");
                    button.classList.add("show");
                    button.innerText = "显示现售型号";
                    listItem.forEach(v=>{
                        if(v.classList.contains("status02")){
                            v.style.display = "block";
                        }
                        else{
                            v.style.display = "none";
                        }
                    })
                }
            })
        }
    }

    /*let listListItem = document.querySelectorAll(".list-item.list");
    if(listListItem.length > 0){
        let maxHeight = 0;
        listListItem.forEach(v=>{
            if(v.offsetHeight > maxHeight){
                maxHeight = v.offsetHeight;
            }
        })
        listListItem.forEach(v=>{
            v.style.height = maxHeight + "px";
        })
    }*/



    function setupRelatedProduct(init){
        if(document.querySelector(".relatedproduct-root") != null){
            let itemNum = document.querySelector(".relatedproduct").querySelectorAll(".related-product-item").length;
            let windowWidth = window.innerWidth;
            let relatedProduct;
            if(init){
                relatedProduct = new Swiper(document.querySelector(".relatedproduct .swiper"),{
                    pagination : {
                        el: " .swiper-pagination",
                        clickable :true,
                    },
                })
            }else{
                relatedProduct = document.querySelector(".relatedproduct .swiper").swiper;
            }

            let perView,totalPage,currentPage;

            if(windowWidth < 480){
                perView = 1;
            }
            else if(windowWidth >= 480 && windowWidth < 768){
                perView = 3;
            }
            else if(windowWidth >= 768 && windowWidth <= 1024){
                perView = 4;
            }else{
                perView = 5;
            }
            if(itemNum > perView){
                totalPage = Math.ceil(itemNum / perView);
                currentPage = 1;
                relatedProduct.slidesPerView = perView;
                relatedProduct.slidesPerGroup = perView;
                relatedProduct.pagination.enable();
                relatedProduct.el.parentNode.querySelector(".swiper-button-prev").style.display = "block"
                relatedProduct.el.parentNode.querySelector(".swiper-button-next").style.display = "block"
                relatedProduct.enable();
                relatedProduct.update();
            }else{
                relatedProduct.el.parentNode.querySelector(".swiper-button-prev").style.display = "none"
                relatedProduct.el.parentNode.querySelector(".swiper-button-next").style.display = "none"
                relatedProduct.pagination.disable();
                relatedProduct.disable();
            }

            document.querySelector(".relatedproduct .swiper-button-prev").addEventListener("click",function(){
                if(currentPage != 1){
                    relatedProduct.slidePrev();
                    currentPage--;
                }
            })

            document.querySelector(".relatedproduct .swiper-button-next").addEventListener("click",function(){
                if(currentPage != totalPage){
                    relatedProduct.slideNext();
                    currentPage++;
                }

            })
        }
    }

    function setupRelatedLink(init){
        if(document.querySelector(".relatedlink") != null){
            let itemNum = document.querySelector(".relatedlink").querySelectorAll(".related-link-item").length;
            let windowWidth = window.innerWidth;
            let relatedLink;
            if(init){
                relatedLink = new Swiper(document.querySelector(".relatedlink .swiper"),{
                    pagination : {
                        el: " .swiper-pagination",
                        clickable :true,
                    },
                })
            }else{
                relatedLink = document.querySelector(".relatedlink .swiper").swiper;
            }
            let perView;
            if(windowWidth < 480){
                perView = 1;
            }
            else if(windowWidth >= 480 && windowWidth < 768){
                perView = 3;
            }
            else if(windowWidth >= 768 && windowWidth <= 1024){
                perView = 4;
            }else{
                perView = 5;
            }
            if(itemNum > perView){
                relatedLink.slidesPerView = perView;
                relatedLink.slidesPerGroup = perView;
                relatedLink.pagination.enable();
                relatedLink.el.parentNode.querySelector(".swiper-button-prev").style.display = "block"
                relatedLink.el.parentNode.querySelector(".swiper-button-next").style.display = "block"
                relatedLink.enable();
                relatedLink.update();
            }else{
                relatedLink.el.parentNode.querySelector(".swiper-button-prev").style.display = "none"
                relatedLink.el.parentNode.querySelector(".swiper-button-next").style.display = "none"
                relatedLink.pagination.disable();
                relatedLink.disable();
            }


            document.querySelector(".relatedlink .swiper-button-prev").addEventListener("click",function(){
                relatedLink.slidePrev();
            })

            document.querySelector(".relatedlink .swiper-button-next").addEventListener("click",function(){
                relatedLink.slideNext();
            })
        }
    }

    function setupNavItem(){
        let root = document.querySelector(".root .cmp-container");
        let nav = document.querySelector(".navigator .navigator-tabs ul");
        for(let i = 0;i<root.children.length ; i++){
            if(root.children[i].getAttribute("id") != null && root.children[i].firstElementChild != null){
                let name = root.children[i].firstElementChild.getAttribute("name")
                let id = root.children[i].getAttribute("id")
                let liTag = document.createElement("li");
                if(root.children[i].firstElementChild.style.display != 'none'){
                    appendHTML("a",name,[],[{"key":"href","value":"#" + id}],liTag);
                    nav.appendChild(liTag);
                }
            }
        }
    }

    function bindNavEvent(){
        if(navigator!=null){
            let navItem = navigator.querySelectorAll(".navigator-tabs a");
            navItem.forEach(v=>{
                v.addEventListener("click",function (e){
                    e.preventDefault();
                    let sectionId = v.getAttribute("href");
                    let ele = document.querySelector(sectionId);
                    window.scrollTo({
                        top: ele.offsetTop - navigatorHeight - 50,
                        behavior: "smooth"
                    });
                    if(window.innerWidth < 992){
                        navigator.querySelector(".navigator-mobile-header a").click();
                    }
                })
            })

            let mobileNav = navigator.querySelector(".navigator-mobile-header a");
            mobileNav.addEventListener("click",function(e){
                e.preventDefault();
                let btn = e.currentTarget;
                let navTitle = navigator.querySelector(".navigator-title");
                let navTabs = navigator.querySelector(".navigator-tabs");
                if(btn.classList.contains("active")){
                    btn.classList.remove("active")
                    navTitle.style.display = "none" ;
                    navTabs.style.display = "none" ;
                }else{
                    btn.classList.add("active");
                    navTitle.style.display = "block" ;
                    navTabs.style.display = "block" ;
                }
            })
        }
    }

    function appendHTML(tagName,innerHTML,classes,properties,parent){
        let tag = document.createElement(tagName);
        classes.forEach(v=>{
            tag.classList.add(v);
        })
        properties.forEach(v=>{
            tag[v.key] = v.value;
        })
        tag.innerHTML = innerHTML;
        parent.appendChild(tag);
    }


    function windowResize(){
        waterFall();
        setupRelatedProduct();
        setupRelatedLink();
        if(accessory != null){
            setupAccy(accessory,accyItems);
        }
    }

    function windowScroll(){
        fixNav();
        calcGalleryHeight();
        showBackToTop();
        checkNavigatorLocation();
    }

    setupNavItem();
    bindNavEvent();
    waterFall();
    setupRelatedProduct(true);
    setupRelatedLink(true);


    if(document.body.classList.contains("model-page")){
        let breadCrumb = document.querySelectorAll(".breadcrumb-container a");
        let ProductCategory,ProductSubCategory,ProductSeries,ProductModelName;
        if(breadCrumb.length > 0){
            ProductCategory = breadCrumb[1] !== undefined ? breadCrumb[1].innerText : "";
            ProductSeries = breadCrumb.length > 3 ? breadCrumb[breadCrumb.length - 2].innerText : "";
            ProductModelName = breadCrumb.length > 3 ? breadCrumb[breadCrumb.length - 1].innerText : "";
            let arr = [];
            breadCrumb.forEach((v,i)=>{
                if(i>1 && i < breadCrumb.length - 2){
                    arr.push(v.innerText);
                }
            })

            ProductSubCategory = arr.join("_")
        }

        window.psc.pageView("pageView",{
            "ProductType":document.querySelector(".banner-container h1") != null ? document.querySelector(".banner-container h1").getAttribute("type") : "",
            "ProductCategory":ProductCategory,
            "ProductSubCategory":ProductSubCategory,
            "ProductSeries":ProductSeries,
            "ProductModelName":ProductModelName
        })

        let bannerButton = document.querySelectorAll(".banner-title a");
        bannerButton.forEach(v=>{
            v.addEventListener("click",function(e){
                let ele = e.currentTarget;
                if(ele.innerText == '联系我们'){
                    window.psc.tracking("clickEvent",{
                        "clickEvent":"ContactUs:PDP-top"
                    })
                }
            })
        })

        let navButton = document.querySelectorAll(".navigator-container .navigator-button a");
        navButton.forEach(v=>{
            v.addEventListener("click",function(e){
                let ele = e.currentTarget;
                if(ele.innerText == '导出PDF'){
                    window.psc.tracking("clickEvent",{
                        "clickEvent":"PdfDownload:PDP-top"
                    })
                }
            })
        })
    }
    window.addEventListener("resize",windowResize);
    window.addEventListener("scroll",windowScroll);
})()

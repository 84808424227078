(function(){
    /*let siteMap = document.querySelectorAll(".sitemap");
    if(siteMap.length > 0){
        let maxHeight = 0;
        siteMap.forEach(v=>{
            if(v.offsetHeight > maxHeight){
                maxHeight = v.offsetHeight;
            }
        })

        siteMap.forEach(v=>{
            v.style.height = maxHeight + "px";
        })
    }*/
})()
(function() {

    window.adobeDataLayer = window.adobeDataLayer || [];

    function query(url, method, data) {
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.timeout = 3000;
            xhr.addEventListener("readystatechange", function () {
                if (this.readyState === 4) {
                    let res = JSON.parse(this.responseText);
                    resolve(res);
                }
            });
            xhr.onerror = (res) => {
                reject(res);
            }
            xhr.open(method, url);
            xhr.setRequestHeader("Content-Type", "application/json");

            xhr.send(data);
        })

    }

    function tracking(event,eventOjb){
        adobeDataLayer.push({
            event:event,
            eventInfo:eventOjb
        })
    }

    function pageView(event,productObj){
        adobeDataLayer.push({
            event:event,
            productObj
        })
    }

    function addEventListener($dom, $evtNameList, $fun, $callback, $option = {
        capture: false
    }) {
        if (!$dom) {
            return false;
        }
        if ($dom.attachEvent) {
            if (Array.isArray($evtNameList)) {
                $evtNameList.forEach($evt => {
                    $dom.attachEvent("on" + $evt, $fun);
                })
            } else if (typeof $evtNameList === 'string') {
                $dom.attachEvent("on" + $evtNameList, $fun);
            }

            if (typeof $callback === 'function') {
                $callback();
            }
        } else if ($dom.addEventListener) {
            if (Array.isArray($evtNameList)) {
                $evtNameList.forEach($evt => {
                    $dom.addEventListener($evt, $fun, false ? $option : ($option.capture || false));
                })
            } else if (typeof $evtNameList === 'string') {
                $dom.addEventListener($evtNameList, $fun, false ? $option : ($option.capture || false));
            }

            if (typeof $callback === 'function') {
                $callback();
            }
        }
    }

    let commonFun = {
        query,
        tracking,
        pageView,
        addEventListener
    };

    window.psc = window.psc || {};
    window.psc = {...window.psc, ...commonFun}
})()

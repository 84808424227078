(function () {
  "use strict";
  let isWeChat = window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger';
  let isAndroid = /(Android)/i.test(navigator.userAgent);
  let isSafari = /Safari/.test(navigator.userAgent);
  let isMobile = (/phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|webOS|android/i.test(navigator.userAgent));
  let selectors = {
    self: '.h5-video',
    h5Video: '.h5_video',
    videoContainer: "videoContainer",
    control: 'control',
    weChatVideoControl: 'weChatVideoControl',
    video: 'video',
    videoImg: 'videoImg',
    pcVideo:'.pcVideo',
    mobileVideo:'.mobVideo',
    voiceBtn: '.voice-btn'
  };
  function VideoPlayer(config) {
    function playClick($event) {
      let ele = $event.currentTarget;
      let videoElement = ele.parentElement.parentElement.querySelector(selectors.video);
      if (ele.classList.contains('playing')) {
        videoElement.play();
        let videoImg = ele.parentElement.parentElement.parentElement.getElementsByClassName(selectors.videoImg);
        if (videoImg && videoImg.length>0) {
          videoImg[0].style.display=selectors.none;
        }
      } else {
        videoElement.pause();
      }
      ele.classList.toggle('playing');
      ele.classList.toggle('paused');
    }

    function switchVoiceIcon (voiceBtn, muted) {
      let muteIcon = voiceBtn.querySelector('.mute');
      let unmuteIcon = voiceBtn.querySelector('.unmute');

      if (muted) {
        muteIcon.classList.add('show');
        unmuteIcon.classList.remove('show');
      } else {
        muteIcon.classList.remove('show');
        unmuteIcon.classList.add('show');
      }
    }

    function playVoice ($event) {
      let ele = $event.currentTarget;
      let videoElement = ele.parentElement.parentElement.querySelector(selectors.video);

      if (!videoElement) {
        return;
      }

      videoElement.muted = !videoElement.muted;

      switchVoiceIcon(ele, videoElement.muted);
    }

    function observeVideoEle (ele, voiceBtn) {
      if (!ele) {
        return;
      }

      let prevMuted = true;
      let io = new IntersectionObserver((entries) => {
        if (ele.paused) {
          return;
        }

        if (entries[0].isIntersecting) {
          ele.muted = prevMuted;
        } else {
          prevMuted = ele.muted;
          ele.muted = true;
        }

        switchVoiceIcon(voiceBtn, ele.muted);
      }, {
        threshold: .8
      });

      io.observe(ele);
    }

    //video
    let h5Video;
    if (isMobile) {
      if (config.element.querySelector(selectors.mobileVideo)) {
        h5Video = config.element.querySelector(selectors.mobileVideo).parentElement.parentElement;
      }
    } else {
      if (config.element.querySelector(selectors.pcVideo)) {
        h5Video = config.element.querySelector(selectors.pcVideo).parentElement.parentElement;
      }
    }
    if(h5Video){
      let videoImg = h5Video.getElementsByClassName(selectors.videoImg);
      let videoContainer = h5Video.getElementsByClassName(selectors.videoContainer);
      let control = videoContainer[0].getElementsByClassName(selectors.control);
      let voiceBtn = videoContainer[0].querySelector('.voice-btn');
      if(videoImg && videoImg.length>0){
        // videoContainer[0].style.display='none';
        let videoElement = h5Video.getElementsByTagName(selectors.video)[0];
        let isAutoplay = videoElement.getAttribute("autoplay") != null && videoElement.getAttribute("autoplay") == 'autoplay' ?
            true : false;
        if(!isAutoplay){
          control[0].classList.remove("paused");
          control[0].classList.add("playing");
          videoImg[0].style.display = "block";
        }else{
          videoElement.play();
          videoElement.muted = true; //自动播放并静音
        }

        // videoContainer[0].addEventListener("mouseleave",function(){
        //   control[0].style.display = 'none';
        // })
        //
        // videoContainer[0].addEventListener("mouseenter",function(){
        //   control[0].style.display = 'block';
        // })

        //兼容安卓微信浏览器处理
        if (isWeChat && isAndroid) {
          let weChatVideoControl = h5Video.getElementsByClassName(selectors.weChatVideoControl);
          if(weChatVideoControl && weChatVideoControl.length > 0){
            weChatVideoControl[0].style.display='block';
            weChatVideoControl[0].addEventListener("click",function(){
              //videoElement.play();
              videoElement.muted = true;
            },false)
          }
          videoElement.addEventListener("timeupdate",function(){
            if(videoElement.currentTime > 0){
              if(weChatVideoControl && weChatVideoControl.length > 0) {
                weChatVideoControl[0].style.display = 'none';
              }
              videoImg[0].style.display='none';
              videoContainer[0].style.display='block';
              videoElement.removeEventListener("timeupdate");
            }
          },false)
        }else{
          if(isSafari){
            videoElement.addEventListener('canplay', function() {
              videoImg[0].style.display='none';
              videoContainer[0].style.display='block';
            }, false);
          }
          videoElement.addEventListener('playing', function () {
            videoImg[0].style.display='none';
            videoContainer[0].style.display='block';
          });
          if (videoElement.currentTime !== 0) {
            videoImg[0].style.display='none';
            videoContainer[0].style.display='block';
          }
        }
      }
      //传入当前绑定事件的元素
      if (control && control.length>0) {
        control[0].addEventListener("click", playClick);
      }

      if (voiceBtn) {
        window.psc.addEventListener(voiceBtn, 'click', playVoice);
        observeVideoEle(h5Video.parentElement.parentElement.querySelector(selectors.video), voiceBtn);
      }
    }
  }
  function H5VideoWeChatInitVideo(){
    //兼容微信浏览器处理
    if (isWeChat) {
      let h5Video = document.querySelectorAll(".mobile>.h5_video>.videoContainer");
      if(h5Video){
        h5Video.forEach(function(TNode){
          document.addEventListener('touchstart', function(){
            let playing = TNode.querySelector('.playing');
            if(!playing) {
              //TNode.querySelector("video").play()
            }}, false);
        })
      }
    }
  }
  function onDocumentReady() {
    let elements = document.querySelectorAll(selectors.self);



    for (let i = 0; i < elements.length; i++) {
      // 视频src单端加载
      if(isMobile) {
        let mobileElement = elements[i].querySelector(selectors.mobileVideo);
        if (mobileElement) {
          let videoPath = mobileElement.getAttribute('data-url');
          let videoType = mobileElement.getAttribute('data-type')
          if (videoPath) {
            // mobileElement.querySelector('source').setAttribute('src',videoPath);
            // let sourceDom = `<source type="${videoType}" src="${videoPath}">`
            // mobileElement.innerHTML = sourceDom;
            mobileElement.setAttribute('src',videoPath);
          }
        }
      }else {
        let pcElement = elements[i].querySelector(selectors.pcVideo);
        if (pcElement) {
          let videoPath = pcElement.getAttribute('data-url');
          if (videoPath) {
            pcElement.setAttribute('src',videoPath);
          }
        }
      }
      // new VideoPlayer({ element: elements[i] });
      // H5VideoWeChatInitVideo();

      var videos = document.getElementsByTagName('video')
      for (let i =0;i<videos.length;i++){
        videos[i].addEventListener('play', function(){
          var vs = document.getElementsByTagName('video')
          for (let j = 0; j < vs.length; j++) {
            if (vs[j]!==this)
              vs[j].pause();
          }
        })
      }
    }
  }

  function onDocumentReady2() {
    var videos = document.getElementsByTagName('video')
    for (let i =0;i<videos.length;i++){
      videos[i].addEventListener('play', function(){
        var vs = document.getElementsByTagName('video')
        for (let j = 0; j < vs.length; j++) {
          if (vs[j]!==this)
            vs[j].pause();
        }
      })
    }
  }

  if (document.readyState !== "loading") {
    onDocumentReady2();
  } else {
    document.addEventListener("DOMContentLoaded", onDocumentReady2);
  }


}());
(function(){
    function getQueryString(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null)
            return decodeURIComponent(r[2]);
        return null;
    }

    let searchResult = document.querySelector(".searchresult");
    let searchBar = document.querySelector(".searchinputbar");
    let headerSearchBar = document.querySelector(".searchbar");
    let currentPage = 1;
    let firstSearch = true;
    let productCurrentPage = 1;
    let pageSize = 10;
    let productPageSize = 5;
    // let keywords = getQueryString("qs")||getQueryString("q");
    let keywords = getQueryString("q");
    let quickKeywords = "";
    let totalCount = 0;
    let productTotalCount = 0;
    let isProfessionalResultMax = false;
    let isProductResultMax = false;
    let noProfessionalResult = false;
    let noProductResult = false;

    // if(keywords != null && searchResult != null){
    //     searchBar.querySelector(".typeahead-default input").value = keywords;
    //     doSearch("g",keywords,currentPage,pageSize,true,true);
    // }

    // if(searchResult != null){
    //     searchResult.querySelector(".professional-search-container .search-result-more button").addEventListener("click",function(){
    //         currentPage ++;
    //         doProfessionalSearch("g",keywords,currentPage,pageSize,false);
    //     })
    //     searchResult.querySelector(".sonystyle-search-container .search-result-more button").addEventListener("click",function(){
    //         productCurrentPage ++;
    //         doSonystyleSearch("g",keywords,productCurrentPage,productPageSize,false);
    //     })
    //     searchResult.querySelector(".professional-search-container .search-result-less button").addEventListener("click",function(){
    //         // 隐藏所有result，显示上一页,删除下面所有page
    //         searchResult.querySelectorAll('.professional-search-container .search-result-box').forEach((item,index) =>{
    //             item.style.display = 'none';
    //             if (index+1 >= currentPage) {
    //                 item.remove();
    //             }
    //         });
    //         currentPage --;
    //         isProfessionalResultMax = false;
    //         searchResult.querySelector("#professionalPage"+currentPage).style.display = "block";
    //         if (currentPage == 1) {
    //             searchResult.querySelector(".professional-search-container .search-result-less").style.display = 'none';
    //         }
    //         searchResult.querySelector(".professional-search-container .search-result-more").style.display = 'block';
    //     })
    //     searchResult.querySelector(".sonystyle-search-container .search-result-less button").addEventListener("click",function(){
    //         // 隐藏所有result，显示上一页,删除下面所有page
    //         searchResult.querySelectorAll('.sonystyle-search-container .search-result-box').forEach((item,index) =>{
    //             item.style.display = 'none';
    //             if (index+1 >= productCurrentPage) {
    //                 item.remove();
    //             }
    //         });
    //         productCurrentPage --;
    //         isProductResultMax = false;
    //         searchResult.querySelector("#sonystylePage"+productCurrentPage).style.display = "block";
    //         if (productCurrentPage == 1) {
    //             searchResult.querySelector(".sonystyle-search-container .search-result-less").style.display = 'none';
    //         }
    //         searchResult.querySelector(".sonystyle-search-container .search-result-more").style.display = 'block';
    //     })
    // }

    if(searchBar != null){
        // searchBar.querySelector(".search-button-container button").addEventListener("click",function (){
        //     research();
        // })

        // searchBar.querySelector(".typeahead-default input").addEventListener("keydown",function(e){
        //     if(e.keyCode == 13){
        //         research();
        //     }
        // })

        searchBar.querySelector(".typeahead-default input").addEventListener("input",function(e){
            let ele = e.currentTarget;
            let value = ele.value;
            quickKeywords = value;
            if(value.length >=3){
                doQuickSearch("g",value,1,5,true,"");
            }
        })

        searchBar.querySelector(".typeahead-default input").addEventListener("focus",function(e){
            window.psc.tracking("Search:Activate",{
                "SearchLocation":"FromSearchPage"
            })
        })
    }

    if(headerSearchBar != null){
        let input = headerSearchBar.querySelector("input");
        if(input != null){
            input.addEventListener("input",function(e){
                let ele = e.currentTarget;
                let value = ele.value;
                quickKeywords = value;
                if(value.length >=3){
                    doQuickSearch("g",value,1,5,true,"header");
                }
            })

            input.addEventListener("keydown",function(e){
                if(e.keyCode == 13 && e.currentTarget.value != ''){
                    window.psc.tracking("Search:Submit",{
                        "SearchKeyword":e.currentTarget.value,
                        "SearchLocation":"FromNavigation",
                        "SearchSubmitType":"User Search"
                    })
                    //window.location.href = "/content/professional/search.html?q=" + encodeURIComponent(e.currentTarget.value);
                    window.location.href = `${ENVCONFIG.searchPageUrl}` + "?q=" + encodeURIComponent(e.currentTarget.value);
                }
            })


            input.addEventListener("focus",function(e){
                window.psc.tracking("Search:Activate",{
                    "SearchLocation":"FromNavigation"
                })
            })
        }

        let showAll = headerSearchBar.querySelector(".show-all-results");
        if(showAll !=null){
            showAll.addEventListener("click",function(e){
                e.preventDefault();
                //window.location.href = "/content/professional/search.html?q=" + encodeURIComponent(quickKeywords);

                window.location.href = `${ENVCONFIG.searchPageUrl}` + "?q=" + encodeURIComponent(quickKeywords);
            })
        }
    }

    function research(){
        let word = searchBar.querySelector(".typeahead-default input").value;
        if(word != null){
            if(word.value != ""){
                keywords = word;
                // 初始化 ，重新搜索
                searchResult.querySelector(".professional-search-container .detail-container").innerHTML = "";
                searchResult.querySelector(".sonystyle-search-container .detail-container").innerHTML = "";
                searchResult.querySelector(".sonystyle-search-container .search-result-less").style.display = 'none';
                searchResult.querySelector(".professional-search-container .search-result-less").style.display = 'none';
                isProfessionalResultMax =false;
                isProductResultMax = false;
                productTotalCount = 0;
                totalCount = 0;
                currentPage = 1;
                productCurrentPage = 1;
                firstSearch=true;
                doSearch("g",keywords,currentPage,pageSize,true,true);
            }
        }
    }

    function doQuickSearch(type,keywords,pageNum,pageSize,clear,location){
        /*window.psc.tracking("Search:Submit",{
            "SearchKeyword":keywords,
            "SearchLocation": location == "header" ? "FromNavigation" : "FromSearchPage",
            "SearchSubmitType":"Automatic Search"
        })*/
        var data = JSON.stringify({
            "keyword": keywords,
            "type": type,
            "pagenum": pageNum,
            "pagesize": pageSize
        });
        window.psc.query(`${ENVCONFIG.searchAPI}`,"POST",data).then(res =>{
            if(res.returncode == 200 && res.message == 'Success'){
                /*window.psc.tracking("Search:Result",{
                    "SearchKeyword":keywords,
                    "SearchLocation":"FromNavigation",
                    "SearchQuantity":res.data.resultCount,
                    "SearchResultLocation":"Navigation drop-down result"
                })*/
                if(res.data.products.length > 0){
                    totalCount = res.data.resultCount;
                    appendResult(res.data,clear,location == "header" ? headerSearchBar.querySelector("ul.list-unstyled") : searchBar.querySelector("ul.list-unstyled"),"e",location);
                    if(location == 'header'){
                        headerSearchBar.querySelector(".typeahead-results").style.display = 'block';
                    }
                    else{
                        searchBar.querySelector(".typeahead-results").style.display = 'block';
                    }
                }
            }
        });
        window.psc.query(`${ENVCONFIG.productsKeywordsAPI}` + keywords,"GET", {}).then(res=>{
            if(res.returncode === 200 && res.message === 'Success'){
                if(res.data.length > 0){
                    productTotalCount = res.data.resultCount;
                    appendProductResult(res.data,clear,location == "header" ? headerSearchBar.querySelector("ul.list-products") : searchBar.querySelector("ul.list-products"),location)
                    if(location == 'header'){
                        headerSearchBar.querySelector(".typeahead-results").style.display = 'block';
                    }
                    else{
                        searchBar.querySelector(".typeahead-results").style.display = 'block';
                    }
                }
            }
        },function (res) {
        })
    }
    function doProfessionalSearch(type,keywords,pageNum,pageSize,clear) {
        var data = JSON.stringify({
            "keyword": keywords,
            "type": type,
            "pagenum": pageNum,
            "pagesize": pageSize
        });
        if (!isProfessionalResultMax) {
            var div = document.createElement('div');
            div.classList.add('search-result-box');
            div.setAttribute('id','professionalPage'+pageNum);
            window.psc.query(`${ENVCONFIG.searchAPI}`,"POST",data).then(res =>{
                if(res.returncode == 200 && res.message == 'Success'){
                    // if (!firstSearch) {
                    //     window.psc.tracking("Search:Result",{
                    //         "SearchKeyword":keywords,
                    //         "SearchLocation":"FromSearchPage",
                    //         "SearchQuantity":res.data.resultCount,
                    //         "SearchQuantity_C": productTotalCount,
                    //         "SearchResultLocation":"Search page results"
                    //     })
                    //     totalCount = res.data.resultCount;
                    // }
                    if(res.data.products.length > 0){
                        noProfessionalResult = false;
                        var title = document.createElement('div');
                        title.classList.add('search-result-title');
                        title.classList.add('professional');
                        title.innerHTML = '<h2>专业产品</h2>';
                        if (div.querySelector('.search-result-title.product') != null) {
                            div.insertBefore(title,div.querySelector('.search-result-title.product'));
                        }else {
                            div.appendChild(title);
                        }
                        let listDiv = document.createElement('div');
                        listDiv.classList.add('search-result-container');
                        listDiv.classList.add('professional');

                        totalCount = res.data.resultCount;
                        appendResult(res.data,clear,listDiv,"g");
                        div.appendChild(listDiv);

                        document.querySelector('.professional-search-container .detail-container').appendChild(div);
                        searchResult.querySelectorAll('.professional-search-container .search-result-box').forEach((item,index) =>{
                            item.style.display = 'none';
                        });
                        searchResult.querySelector('#professionalPage'+(pageNum)).style.display = 'block';

                        if((currentPage - 1) * pageSize + res.data.products.length >= totalCount){
                            isProfessionalResultMax = true;
                        }
                        else{
                            isProfessionalResultMax = false;
                        }
                    }else {
                        isProfessionalResultMax = true;
                        // 判断第一次搜索没有数据
                        if (pageNum == 1) {
                            noProfessionalResult = true;
                        }else {
                            noProfessionalResult = false;
                        }
                    }
                    // 判断显示上下页button
                    if (!isProfessionalResultMax) {
                        searchResult.querySelector(".professional-search-container .search-result-more").style.display = 'block';
                    }else {
                        searchResult.querySelector(".professional-search-container .search-result-more").style.display = 'none';
                    }
                    if (pageNum > 1) {
                        searchResult.querySelector(".professional-search-container .search-result-less").style.display = 'block';
                    }else {
                        searchResult.querySelector(".professional-search-container .search-result-less").style.display = 'none';
                    }
                }
            })
        }
    }
    function doSonystyleSearch(type,keywords,pageNum,pageSize,clear) {
        if (!isProductResultMax) {
            var div = document.createElement('div');
            div.classList.add('search-result-box');
            div.setAttribute('id','sonystylePage'+pageNum);
            let productsData = JSON.stringify({
                "searchkeyword":keywords,
                "searchcategory":null,
                "searchsubcategory":null,
                "searchminprice":null,
                "searchmaxprice":null,
                "searchnewselected":false,
                "searchpromotionselected":false,
                "pagenum":pageNum,
                "pagesize":pageSize,
                "pricesort":"",
                "basestore":"SS",
                "producttype":"",
                "bpid":"",
                "channel":"WEB",
                "pval":""
            })
            window.psc.query(`${ENVCONFIG.productsSearchAPI}`,"POST",productsData).then(res =>{
                if (res.returncode == 200 && res.message == 'Success') {
                    // if (!firstSearch) {
                    //     window.psc.tracking("Search:Result",{
                    //         "SearchKeyword":keywords,
                    //         "SearchLocation":"FromSearchPage",
                    //         "SearchQuantity_C":res.data.resultCount,
                    //         "SearchQuantity":totalCount,
                    //         "SearchResultLocation":"Search page results"
                    //     })
                    //     productTotalCount = res.data.resultCount;
                    // }
                    if (res.data.products.length > 0) {
                        noProductResult = false;
                        var title = document.createElement('div');
                        title.classList.add('search-result-title');
                        title.classList.add('product');
                        title.innerHTML = '<h2>消费电子类产品</h2>';
                        div.appendChild(title);
                        let listDiv = document.createElement('div');
                        listDiv.classList.add('search-result-container');
                        listDiv.classList.add('product');
                        productTotalCount = res.data.resultCount;
                        appendProductSearchResult(res.data.products,clear,listDiv);
                        div.appendChild(listDiv);

                        document.querySelector('.sonystyle-search-container .detail-container').appendChild(div);
                        searchResult.querySelectorAll('.sonystyle-search-container .search-result-box').forEach((item,index) =>{
                            item.style.display = 'none';
                        });
                        searchResult.querySelector('#sonystylePage'+(pageNum)).style.display = 'block';

                        if (pageNum > 1) {
                            searchResult.querySelector(".sonystyle-search-container .search-result-less").style.display = 'block';
                        }

                        if (productTotalCount == (pageNum - 1) * pageSize + res.data.products.length) {
                            isProductResultMax =true;
                        }else {
                            isProductResultMax =false;
                        }
                    }else {
                        isProductResultMax =true;
                    }
                }else {
                    isProductResultMax =true;
                    if (pageNum == 1) {
                        noProductResult = true;
                    }else {
                        noProductResult = false;
                    }
                }
                if (isProductResultMax) {
                    searchResult.querySelector(".sonystyle-search-container .search-result-more").style.display = 'none';
                }else {
                    searchResult.querySelector(".sonystyle-search-container .search-result-more").style.display = 'block';
                }
            },function () {
            });
        }
    }
    function doSearch(type,keywords,pageNum,pageSize,clear,disableTracking){
        if(disableTracking){
            window.psc.tracking("Search:Submit",{
                "SearchKeyword":keywords,
                "SearchLocation":"FromSearchPage",
                "SearchSubmitType":"User Search"
            })
        }
        // 专品和普品分开调用接口，分开渲染
        doProfessionalSearch(type,keywords,pageNum,pageSize,clear);
        pageSize = productPageSize;
        pageNum = productCurrentPage;
        doSonystyleSearch(type,keywords,pageNum,pageSize,clear);

        setTimeout(function () {
            if(disableTracking){
                window.psc.tracking("Search:Result",{
                    "SearchKeyword":keywords,
                    "SearchLocation":"FromSearchPage",
                    "SearchQuantity_C":productTotalCount,
                    "SearchQuantity":totalCount,
                    "SearchResultLocation":"Search page results"
                })
                firstSearch = false;
            }
            if (noProfessionalResult && noProductResult) {
                searchResult.querySelector(".sonystyle-search-container").style.display = 'none';
                searchResult.querySelector(".professional-search-container").style.display = 'none';
                searchResult.querySelector(".no-search-result").style.display = 'flex';
            }else {
                searchResult.querySelector(".sonystyle-search-container").style.display = 'block';
                searchResult.querySelector(".professional-search-container").style.display = 'block';
                searchResult.querySelector(".no-search-result").style.display = 'none';
            }
        },2000)
    }

    function appendResult(data,clear,container,type,location){
        if(clear){
            container.innerHTML = "";
        }
        data.products.forEach((v,i)=>{
            if(type == 'e'){
                container.appendChild(createQuickItem(v,location,i,totalCount,data.resultCount));
            }else{
                container.appendChild(createItem(v,i));
            }
        })
    }
    function appendProductSearchResult(data,clear,container) {
        if(clear){
            container.innerHTML = "";
        }
        data.forEach((item,i) => {
            container.appendChild(createProductSearchItem(item,i));
        })
    }
    function appendProductResult(data,clear,container,location){
        if(clear){
            container.innerHTML = "";
        }
        data.forEach((v,i)=>{
            container.appendChild(createProductQuickItem(v,location,i));
        })
    }
    function createProductSearchItem(data,i) {
        // 如果是产品。需要调用产品详情接口获取详情页链接
        // if (data.type == 'product') {
        //     window.psc.query(`${ENVCONFIG.productsURLAPI}`,"GET",{"sku":data.prod8did}).then(res =>{
        //         if (res.returncode == 200 && res.message == 'Success' && res.data != null) {
        //             data.url = res.data.gallery.contentUrl;
        //             renderProductSearchItem(data);
        //         }
        //     });
        // }else {
        data.url =data.imgclickurl ? ( data.imgclickurl.lastIndexOf("https://") > -1 ? data.imgclickurl : `${ENVCONFIG.productsDomain}` + data.imgclickurl) : '#';
        return renderProductSearchItem(data,i);
        // }
    }
    function renderProductSearchItem(item,i) {
        let div =  document.createElement("a");
        div.setAttribute("class","search-result-item");
        div.setAttribute("href",item.url);
        div.setAttribute('target',"_blank");
        let iconDiv = document.createElement("div");
        iconDiv.setAttribute("class","icons_lists");
        if (item.type != 'activity') {
            // 渲染上方标签
            if (item.status && !item.installmentEntry) {
                let di = document.createElement("div");
                if (item.status == '促销'||item.status == '主推型号'||item.status == '线上专享') {
                    di.classList.add('ioncs_status1');
                }
                if (item.status != '促销'&&item.status != '主推型号'&&item.status != '线上专享') {
                    di.classList.add('ioncs_status2');
                }
                di.innerText = item.status;

                iconDiv.appendChild(di);
            }
            if (item.couponflag == 1 && !item.installmentEntry) {
                var coupImg =  document.createElement("img");
                coupImg.setAttribute('src',`${ENVCONFIG.productsDomain}`+'/etc/designs/sonystyle/images/search/has_coupon_icon.png');
                iconDiv.appendChild(coupImg);
            }
            if (item.installmentEntry) {
                if (!item.installmentEntry.flashbuyActive && !item.installmentEntry.depositPayActive) {
                    var childDiv =document.createElement("div");
                    childDiv.classList.add('installmentIcon');
                    var text = item.installmentEntry.numOfInstallments + '期免息 低至'+item.installmentEntry.instaAmountWithoutInterest +'元/期';
                    childDiv.innerText = text;
                    iconDiv.appendChild(childDiv);
                }else if (item.installmentEntry.flashbuyActive) {
                    var childDiv =document.createElement("div");
                    childDiv.classList.add('installmentIcon');
                    var text = '最高可享'+item.installmentEntry.numOfInstallments +'期免息';
                    childDiv.innerText = text;
                    iconDiv.appendChild(childDiv);
                }else if (item.installmentEntry.depositPayActive) {
                    var childDiv =document.createElement("div");
                    childDiv.classList.add('installmentIcon');
                    var text = '尾款最高可享'+item.installmentEntry.numOfInstallments +'期免息';
                    childDiv.innerText = text;
                    iconDiv.appendChild(childDiv);
                }
            }
            div.appendChild(iconDiv);
            // 渲染产品图片
            let imgDiv = document.createElement("div");
            imgDiv.classList.add('search-result-image');

            imgDiv.innerHTML = '<img src="'+`${ENVCONFIG.productsDomain}`+item.imgurl+'.thumb.200.200.png" alt="'+item.longname+'" aria-hidden="true">';
            div.appendChild(imgDiv);
            // 渲染标题和价格
            let detailDiv = document.createElement("div");
            detailDiv.classList.add("search-result-detail");
            let h3 =  document.createElement("h3");
            let a = document.createElement('a');
            a.setAttribute('href',item.url);
            a.setAttribute('title',item.longname);
            a.setAttribute('target',"_blank");
            a.innerText = item.longname;
            h3.appendChild(a);
            detailDiv.appendChild(h3);
            let priceDiv = document.createElement("div");
            priceDiv.classList.add('pro_pirces');
            if (item.defaultprice != null && item.defaultprice != undefined) {
                let span = document.createElement("span");
                span.innerText = 'RMB';
                priceDiv.appendChild(span);
                let font = document.createElement("font");
                font.innerText = thousand(item.defaultprice);
                priceDiv.appendChild(font);
            }
            detailDiv.appendChild(priceDiv);
            div.appendChild(detailDiv);
        }else {
            if (item.status) {
                let di = document.createElement('div');
                di.classList.add('ioncs_status1');
                di.style.backgroundColor = '#' +item.status.split('#')[1].trim();
                di.innerText =item.status.split('#')[0].trim();
                iconDiv.appendChild(di);
            }
            div.appendChild(iconDiv);
            // 渲染产品图片
            let imgDiv = document.createElement("div");
            imgDiv.classList.add('search-result-image');
            imgDiv.innerHTML = '<img src="'+`${ENVCONFIG.productsDomain}`+item.imgurl+'.thumb.200.200.png" alt="'+item.longname+'" aria-hidden="true">';

            div.appendChild(imgDiv);
            // 渲染标题和价格
            let detailDiv = document.createElement("div");
            detailDiv.classList.add("search-result-detail");
            let h3 =  document.createElement("h3");
            let a = document.createElement('a');
            a.setAttribute('href',item.url);
            a.setAttribute('target',"_blank");
            a.setAttribute('title',item.shortname);
            a.innerText = item.shortname;
            h3.appendChild(a);
            detailDiv.appendChild(h3);
            let p = document.createElement("p");
            p.setAttribute("title",item.desc);
            p.innerText = item.desc;
            detailDiv.appendChild(p);
            div.appendChild(detailDiv);
        }
        div.addEventListener("click",function(){
            window.psc.tracking("Search:ResultClick",{
                "SearchKeyword":keywords,
                "SearchClickTitle":item.type != 'activity' ? item.longname : item.shortname,
                "SearchClickRanking":(productCurrentPage - 1) * productPageSize + i,
                "SearchResultLocation":"Search page results",
                "SearchResultType":"2C"
            })
        })
        return div;
    }
    function thousand (value) {
        if(value==null)return ''
        value = value.toString()
        return value.replace(/(?=\B(?:\d{3})+\b)(\d{3}(\.\d+$)?)/g, ',$1')
    };
    function createProductQuickItem(data,location,i){
        let li = document.createElement("li");
        let a = document.createElement("a");
        a.setAttribute("href",`${ENVCONFIG.searchPageUrl}` + "?q=" + encodeURIComponent(data.value));
        if (location != "header") {
            let h5 = document.createElement('h5');
            h5.innerText = data.value;
            li.appendChild(h5);
        }
        a.innerHTML = data.value;
        a.addEventListener("click",function(){
            window.psc.tracking("Search:Submit",{
                "SearchKeyword":quickKeywords,
                "SearchLocation": location == "header" ? "FromNavigation" : "FromSearchPage",
                "SearchSubmitType":"Automatic Search"
            })
            window.psc.tracking("Search:Result",{
                "SearchKeyword":quickKeywords,
                "SearchLocation":location == "header" ? "FromNavigation" : "FromSearchPage",
                "SearchQuantity":totalCount,
                "SearchQuantity_C":productTotalCount,
                "SearchResultLocation":"Navigation drop-down result"
            })
            window.psc.tracking("Search:ResultClick",{
                "SearchKeyword":quickKeywords,
                "SearchClickTitle":data.value,
                "SearchClickRanking":i + 1,
                "SearchLocation":location == "header" ? "FromNavigation" : "FromSearchPage",
                "SearchResultLocation":"Navigation drop-down result",
                "SearchResultType":"2C"
            })
        })
        li.appendChild(a);
        return li;
    }

    function createItem(data,i){
        let searchResultItem = document.createElement("a");
        searchResultItem.classList.add("search-result-item");
        searchResultItem.setAttribute("href",`${ENVCONFIG.domain}` + data.pagepath.replace("/content","") + ".html");
        // icon
        let iconDiv = document.createElement("div");
        iconDiv.setAttribute("class","icons_lists");
        let di = document.createElement("div");
        if(data.status == 'offline'){
            di.classList.add('ioncs_status3');
            di.innerText = "停产";
            iconDiv.appendChild(di);
        }
        searchResultItem.appendChild(iconDiv);
        let searchResultImage = document.createElement("div");
        searchResultImage.classList.add("search-result-image");
        let img = document.createElement("img");
        img.setAttribute("src",data.searchImgurl);
        searchResultImage.appendChild(img);
        searchResultItem.appendChild(searchResultImage);
        let searchResultDetail = document.createElement("div");
        searchResultDetail.classList.add("search-result-detail");
        let h = document.createElement("h3");
        let a = document.createElement("a");
        a.innerHTML = data.longname;
        a.setAttribute('title',data.longname);
        a.setAttribute("href",`${ENVCONFIG.domain}` + data.pagepath.replace("/content","") + ".html");
        h.appendChild(a);

        searchResultDetail.appendChild(h);
        let p = document.createElement("p");
        p.innerHTML = data.seodesc;
        p.setAttribute("title",data.seodesc);
        searchResultDetail.appendChild(p);
        searchResultItem.appendChild(searchResultDetail);
        searchResultItem.addEventListener("click",function(){
            window.psc.tracking("Search:ResultClick",{
                "SearchKeyword":keywords,
                "SearchClickTitle":data.longname,
                "SearchClickRanking":(currentPage - 1) * pageSize + i,
                "SearchResultLocation":"Search page results",
                "SearchResultType":"2B"
            })
        })
        return searchResultItem;
    }

    function createQuickItem(data,location,i,totalCount){
        let li = document.createElement("li");
        let h = document.createElement("h5");
        h.innerHTML = data.shortname;
        if(data.status == 'offline'){
            let span = document.createElement("span");
            span.innerHTML = "停产";
            h.appendChild(span);
        }
        li.appendChild(h);
        if(location == "header"){
            let p = document.createElement("p");
            p.innerHTML = data.seodesc;

            li.appendChild(p);
        }
        let a = document.createElement("a");
        a.setAttribute("data-target","external");
        a.setAttribute("href",`${ENVCONFIG.domain}` + data.pagepath.replace("/content","") + ".html");
        a.classList.add("block-link");
        a.innerHTML = data.shortname;

        a.addEventListener("click",function(){
            window.psc.tracking("Search:Submit",{
                "SearchKeyword":quickKeywords,
                "SearchLocation": location == "header" ? "FromNavigation" : "FromSearchPage",
                "SearchSubmitType":"Automatic Search"
            })
            window.psc.tracking("Search:Result",{
                "SearchKeyword":quickKeywords,
                "SearchLocation":location == "header" ? "FromNavigation" : "FromSearchPage",
                "SearchQuantity":totalCount,
                "SearchQuantity_C":productTotalCount,
                "SearchResultLocation":"Navigation drop-down result"
            })
            window.psc.tracking("Search:ResultClick",{
                "SearchKeyword":quickKeywords,
                "SearchClickTitle":data.shortname,
                "SearchClickRanking":i + 1,
                "SearchLocation":location == "header" ? "FromNavigation" : "FromSearchPage",
                "SearchResultLocation":"Navigation drop-down result",
                "SearchResultType":"2B"
            })
        })

        li.appendChild(a);
        if(location == "header"){
            let img = document.createElement("img");
            img.setAttribute("src",data.searchImgurl);
            img.classList.add("thumbnail");
            li.appendChild(img);
        }

        return li;
    }

    document.body.addEventListener("click",function(){
        if(searchBar != null){
            if(searchBar.querySelector(".typeahead-results") != null){
                if(searchBar.querySelector(".typeahead-results").style.display == 'block'){
                    searchBar.querySelector(".typeahead-results").style.display = 'none'
                }
            }
        }

        if(headerSearchBar.querySelector(".typeahead-results") != null){
            if(headerSearchBar.querySelector(".typeahead-results").style.display == 'block'){
                headerSearchBar.querySelector(".typeahead-results").style.display = 'none'
            }
        }
    })

})()

(function(){
    let footerAgreementButton = document.querySelector("#footer-agreement span");
    if(footerAgreementButton != null){
        footerAgreementButton.addEventListener("click",function (e){
            document.querySelector("#footer-agreement-bottom").style.display = "inline-block";
            document.querySelector("#footer-agreement").style.display = "none";
        })
    }

    let footer = document.querySelector("#footer");
    if(footer != null){
        let footerLinks = footer.querySelectorAll('a');
        footerLinks.forEach(v=>{
            v.addEventListener("click",function (e) {
                window.psc.tracking("clickEvent",{
                    "clickText":"Footer:" + v.innerText,
                    "clickLink":v.getAttribute("href")
                })
            })
        })
    }

    let socialButton = document.querySelectorAll(".footer-social-container a");
    if(socialButton.length > 0){
        socialButton.forEach((v,i)=>{
            v.addEventListener("mouseenter",function (e){
                document.querySelectorAll(".footer-social-container img").length > i ?
                    document.querySelectorAll(".footer-social-container img")[i].classList.add("show") : "";
            })

            v.addEventListener("mouseleave",function (e){
                document.querySelectorAll(".footer-social-container img").length > i ?
                    document.querySelectorAll(".footer-social-container img")[i].classList.remove("show") : "";
            })
        })
    }

    function getParam(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        //search,查询？后面的参数，并匹配正则
        var r = location.search.substr(1).match(reg);
        if (r != null) return decodeURI(decodeURI(r[2]));
    }

    if(getParam('source')=='app' || getParam('source')=='miniprograme'){
        document.getElementById('header').style.display= 'none'
        document.getElementById('footer').style.display= 'none'
    }

    var sony_ua = navigator.userAgent.toLowerCase();
    if (sony_ua.match(/MicroMessenger/i)=="micromessenger") {
        wx.miniProgram.getEnv(function (res) {
            if (res.miniprogram) {
                document.getElementById('header').style.display= 'none'
                document.getElementById('footer').style.display= 'none'
            } else {
                console.log('不在小程序内');
            }
        })
    }
    if(/sonyapp/i.test(navigator.userAgent)){
        document.getElementById('header').style.display= 'none'
        document.getElementById('footer').style.display= 'none'
    }

    // var $alist = document.getElementsByTagName('a');
    // for(var i = 0;i<$alist.length;i++){
    //     $alist[i].click()
    // }

})()

(function(){
    let buttons = document.querySelectorAll(".cmp-button");
    if(buttons.length > 0){
        buttons.forEach(v=>{
            if(v.dataset.overlay !== undefined){
                let overlayId = v.dataset.overlay;
                v.addEventListener("click",function(){
                    document.getElementById(overlayId).parentElement.style.display = "block";
                })
            }
        })
    }


    let overlays = document.querySelectorAll(".overlay-container");
    if(overlays.length > 0){
        overlays.forEach(v=>{
            let closeButton = v.querySelector(".overlay-close");
            closeButton.addEventListener("click",function(e){
                let ele = e.currentTarget;
                ele.parentElement.parentElement.parentElement.style.display = "none";
            })
        })
    }
})()

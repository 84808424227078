(function (){
    var backButton = document.querySelector(".back-icon");
    var docElem = document.documentElement;
    if (backButton != null) {
        backButton.addEventListener('click',function () {
            var scrollTop =docElem.scrollTop || document.body.scrollTop;
            if (scrollTop > 0) {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            }
        })
    }
})()
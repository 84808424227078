(function(){
    let menuClose = document.querySelector(".menuclose button");
    let headerMenu = document.querySelector("#header-menu");
    let headerContainer = document.querySelector("#header-container");
    let headerSearch = document.querySelector("#header-search");
    let header = document.querySelector("#header");
    let menuList = {
        levelOne:[]
    };

    if(menuClose!=null){
        menuClose.addEventListener("click",function(e){
            let button = e.currentTarget;
            let flag = button.classList.contains("collapsed");
            if(flag){
                button.setAttribute("aria-expanded","true");
                button.querySelector(".sr-only").innerText = '打开导航';
                headerContainer.querySelector(".mobile-nav").style.display = "block";
                headerContainer.querySelector(".mobile-nav").style.opacity = "1";
            }else{
                button.setAttribute("aria-expanded","false");
                button.querySelector(".sr-only").innerText = '关闭导航';
                headerContainer.querySelector(".mobile-nav").style.display = "none";
                headerContainer.querySelector(".mobile-nav").style.opacity = "0";
            }
            button.classList.toggle("collapsed");

            document.body.classList.toggle("nav-show");
        })
    }

    if(headerMenu != null){
        let menuButtons = headerMenu.querySelectorAll(".menu-button");
        if(menuButtons.length > 0){
            menuButtons.forEach(v=>{
                // if(v.querySelector("a.cmp-button") == null){
                    v.addEventListener("mouseover",function(e){
                        e.preventDefault();
                        e.stopPropagation();
                        let button = e.currentTarget;
                        let parent = button.parentNode;
                        let mainMenu = parent.querySelector(".main-menu");
                        menuButtons.forEach(v=>{
                            v.classList.remove("hover");
                        })
                        button.classList.add("hover");

                        let allMenu = document.querySelectorAll(".main-menu");
                        // if(allMenu.length > 0){
                            allMenu.forEach(v=>{
                                v.style.visibility = "hidden";
                                v.style.opacity = 0;
                            })
                            switchSearchIcon("search")
                        // }

                        if(mainMenu){
                            // let allMenu = document.querySelectorAll(".main-menu");
                            // if(allMenu.length > 0){
                            //     allMenu.forEach(v=>{
                            //         v.style.visibility = "hidden";
                            //         v.style.opacity = 0;
                            //     })
                            // }
                            mainMenu.style.visibility = "visible";
                            mainMenu.style.opacity = 1;
                            document.body.classList.add("nav-show");
                            switchSearchIcon("close")
                        }
                    },true)
                // }
            })
        }
    }

    if(header != null){
        header.addEventListener("mouseleave",function(e){
            let con = e.currentTarget;
            let menu = con.querySelectorAll(".main-menu");
            let button = con.querySelectorAll(".menu-button");
            menu.forEach(v=>{
                v.style.visibility = "hidden";
                v.style.opacity = 0;
                document.body.classList.remove("nav-show");
            })
            button.forEach(v=>{
                v.classList.remove("hover")
            })
            switchSearchIcon("search")
        })
    }

    if(headerSearch != null){
        if(headerSearch.querySelector(".cmp-button__icon--search") != null){
            headerSearch.querySelector(".cmp-button__icon--search").addEventListener("click",function(e){
                let icon = e.currentTarget;
                let button = header.querySelectorAll(".menu-button");
                if(icon.classList.contains("cmp-button__icon--search")){
                    if(window.innerWidth <= 768){
                        headerSearch.querySelector(".searchbar").style.width = window.innerWidth + "px";
                    }
                    let mobileNav = document.querySelector(".mobile-nav");
                    if(mobileNav != null){
                        mobileNav.classList.toggle("search-show");
                    }
                    headerSearch.querySelector(".searchbar").classList.toggle("active");
                    setTimeout(function(){
                        headerSearch.querySelector("#navigation-typeahad").focus();
                    },550)
                }else{
                    header.querySelectorAll(".main-menu").forEach(v=>{
                        v.style.visibility = "hidden";
                        v.style.opacity = 0;
                        document.body.classList.remove("nav-show");
                    })
                    button.forEach(v=>{
                        v.classList.remove("hover")
                    })
                    switchSearchIcon("search");
                }
            })
        }
    }

    if(headerMenu != null){
        if(headerMenu.querySelectorAll(".menu-button").length > 0){
            headerMenu.querySelectorAll(".menu-button").forEach(v=>{
                let levelOne = v.querySelector(".cmp-button__text");
                let levelOneText = levelOne.innerText;
                let levelOneObj = {};
                levelOneObj.text = levelOneText;
                levelOneObj.link = levelOne.parentNode.getAttribute("href");
                levelOneObj.levelTwo = [];
                levelOneObj.viewAll = [];
                if(v.nextElementSibling != null){
                    let levelTwoButtons = v.nextElementSibling.querySelectorAll(".button.menu-first-item");
                    levelTwoButtons.forEach(j=>{
                        let levelTwoObj = {};
                        levelTwoObj.text = j.querySelector(".cmp-button__text").innerText;
                        levelTwoObj.link = j.querySelector("a") != null ? j.querySelector("a").getAttribute("href") : null;
                        levelTwoObj.levelThree = [];
                        let levelThreeButtons = getMenuLevelThree(j,[]);
                        levelThreeButtons.forEach(o=>{
                            let levelThreeObj = {};
                            levelThreeObj.text = o.querySelector(".cmp-button__text").innerText;
                            levelThreeObj.link = o.querySelector("a").getAttribute("href");
                            levelTwoObj.levelThree.push(levelThreeObj);
                        })
                        levelOneObj.levelTwo.push(levelTwoObj);
                    })

                    let viewAllButtons = v.nextElementSibling.querySelectorAll(".secondary-menu .cmp-button");
                    viewAllButtons.forEach(j=>{
                        let viewAllObj = {};
                        viewAllObj.text = j.querySelector(".cmp-button__text").innerText;
                        viewAllObj.link = j.getAttribute("href");
                        levelOneObj.viewAll.push(viewAllObj);
                    })
                }
                menuList.levelOne.push(levelOneObj);
            })
            let currentLevel = 1;
            let currentLevelOne = "";
            let width = window.innerWidth;
            let mobileNavCon = createHTML("div","",["mobile-nav"],[{key:"style",value:"width:" + width + "px"}]);
            let mobileNav = createHTML("div","",["mobile-nav-container"],[{key:"style",value:"width:" + width + "px;left:0"},]);
            mobileNavCon.appendChild(mobileNav);
            headerContainer.appendChild(mobileNavCon);
            createMobileNav(menuList.levelOne,null,null,mobileNav,width,currentLevel);
            mobileNav.querySelectorAll("button").forEach(v=>{
                v.addEventListener("click",function(e){
                    let button = e.currentTarget;
                    let text = button.innerText;
                    currentLevelOne = text;
                    currentLevel = 2;
                    let levelTwoDom = mobileNav.querySelector(".level-2");
                    if(levelTwoDom == null){
                        let levelTwo = menuList.levelOne.filter(v=>v.text == text)[0].levelTwo;
                        createMobileNav(levelTwo,text,menuList.levelOne.filter(v=>v.text == text)[0].viewAll,mobileNav,width,currentLevel);
                        mobileNav.style.width = width + width + "px";
                    }else{
                        let levelTwoTitleButton = mobileNav.querySelector(".level-2 .title button");
                        if(levelTwoTitleButton.innerText != text){
                            levelTwoDom.parentNode.removeChild(levelTwoDom);
                            let levelTwo = menuList.levelOne.filter(v=>v.text == text)[0].levelTwo;
                            createMobileNav(levelTwo,text,menuList.levelOne.filter(v=>v.text == text)[0].viewAll,mobileNav,width,currentLevel);
                            mobileNav.style.width = width + width + "px";
                        }
                    }
                    mobileNav.style.left = "-" + width + "px";

                    let levelTwoTitleButton = mobileNav.querySelector(".level-2 .title button");
                    if(levelTwoTitleButton!=null){
                        levelTwoTitleButton.addEventListener("click",function(e){
                            mobileNav.style.left = "0";
                        })
                    }

                    let levelTwoListButton = mobileNav.querySelectorAll(".level-2 button");
                    if(levelTwoListButton.length > 0){
                        levelTwoListButton.forEach(j=>{
                            if(!j.classList.contains("title")){
                                j.addEventListener("click",function (e){
                                    let button = e.currentTarget;
                                    let text = button.innerText;
                                    currentLevel = 3;
                                    let levelThreeDom = mobileNav.querySelector(".level-3");
                                    if(levelThreeDom == null){
                                        let levelThree = menuList.levelOne.filter(v=>v.text == currentLevelOne)[0].levelTwo.filter(v=>v.text == text);
                                        if(levelThree.length > 0){
                                            levelThree = levelThree[0].levelThree;
                                            createMobileNav(levelThree,text,[],mobileNav,width,currentLevel);
                                            mobileNav.style.width = width + width + width + "px";
                                            mobileNav.style.left = "-" + (width + width) + "px";
                                        }

                                    }else{
                                        let levelThreeTitleButton = mobileNav.querySelector(".level-3 .title button");
                                        if(levelThreeTitleButton.innerText != text){
                                            levelThreeDom.parentNode.removeChild(levelThreeDom);
                                            let levelThree = menuList.levelOne.filter(v=>v.text == currentLevelOne)[0].levelTwo.filter(v=>v.text == text);
                                            if(levelThree.length > 0){
                                                levelThree = levelThree[0].levelThree;
                                                createMobileNav(levelThree,text,[],mobileNav,width,currentLevel);
                                                mobileNav.style.width = width + width + width + "px";
                                                mobileNav.style.left = "-" + (width + width) + "px";
                                            }
                                        }
                                    }

                                    let levelThreeTitleButton = mobileNav.querySelector(".level-3 .title button");
                                    if(levelThreeTitleButton!=null){
                                        levelThreeTitleButton.addEventListener("click",function(e){
                                            mobileNav.style.left = "-" + width + "px";
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
            });
        }
    }



    window.addEventListener("resize",function(e){
        if(typeof (currentLevel) !== 'undefined'){
            let win = e.currentTarget;
            width = win.screen.width;
            console.log("header :" + width);
            headerContainer.querySelector(".mobile-nav").style.width = width + "px";
            headerContainer.querySelector(".mobile-nav-container").style.width = width * currentLevel + "px";
            headerContainer.querySelector(".mobile-nav-container").style.left = currentLevel == 0 ? 0 :  "-" + width * (currentLevel - 1) + "px";
            headerContainer.querySelectorAll(".mobile-nav-container ul").forEach(v=>{
                v.style.width = width + "px";
            })
        }
        if(headerSearch!= null){
            if(window.innerWidth <= 768){
                headerSearch.querySelector(".searchbar").style.width = window.innerWidth + "px";
            }
        }
    })

    function switchSearchIcon(flag){
        let searchIcon = headerSearch.querySelector(".cmp-button__icon");
        if(flag == 'search'){
            searchIcon.classList.add("cmp-button__icon--search");
            searchIcon.classList.remove("cmp-button__icon--menu-close")
        }
        else{
            searchIcon.classList.remove("cmp-button__icon--search");
            searchIcon.classList.add("cmp-button__icon--menu-close")
        }
    }

    function createHTML(tagName,innerHTML,classes,properties){
        let tag = document.createElement(tagName);
        classes.forEach(v=>{
            tag.classList.add(v);
        })
        properties.forEach(v=>{
            tag[v.key] = v.value;
        })
        tag.innerHTML = innerHTML;
        return tag;
    }

    function createMobileNav(links , title , viewAll,container,width,level){
        let linksList = createHTML("ul","",["level-"+ level],[{key:"style",value:"width:" + width + "px"}]);
        if(title != null){
            let li = createHTML("li",'<button>' + title + '</button>',["title"],[]);
            linksList.appendChild(li);
        }
        links.forEach(v =>{
            let text = v.text;
            let link = v.link;
            if(link != null){
                if(level == 1 && v.levelTwo.length == 0){
                    let li = createHTML("li",'<a href="'+link+'" target="_blank">' + text + '</a>',[],[]);
                    linksList.appendChild(li);
                }
                else if(level == 2 && v.levelThree.length == 0){
                    let li = createHTML("li",'<a href="'+link+'" target="_blank">' + text + '</a>',[],[]);
                    linksList.appendChild(li);
                }
                else if(level == 3){
                    let li = createHTML("li",'<a href="'+link+'" target="_blank">' + text + '</a>',[],[]);
                    linksList.appendChild(li);
                }
                else{
                    let li = createHTML("li",'<button>' + text + '</button>',[],[]);
                    linksList.appendChild(li);
                }
            }else{
                let li = createHTML("li",'<button>' + text + '</button>',[],[]);
                linksList.appendChild(li);
            }
        })
        if(viewAll != null){
            viewAll.forEach(v=>{
                let li = createHTML("li",'<a href="'+v.link+'" target="_blank">' + v.text + '</a>',["all"],[]);
                linksList.appendChild(li);
            })

        }

        container.appendChild(linksList);
    }

    function getMenuLevelThree(ele,arr){
        if(ele.nextElementSibling == null){
            return arr;
        }
        let next = ele.nextElementSibling;
        if(!next.classList.contains("menu-first-item")){
            arr.push(next);
            getMenuLevelThree(next,arr);
        }
        else{
            return arr;
        }
        return arr;
    }
})()

(function () {




    document.addEventListener('DOMContentLoaded', function () {
        // 获取所有 .tab__nav 元素
        var tabNavs = document.querySelectorAll('.galleryNewSection .tab__nav');

        // 为每个 .tab__nav 添加点击事件
        tabNavs.forEach(function (tabNav) {
            tabNav.addEventListener('click', function () {
                // 获取父元素
                var parent = this.closest('.galleryNewSection');

                // 获取当前点击的 .tab__nav 的索引
                var index = Array.from(tabNavs).indexOf(this);

                // 添加 active 类并移除兄弟元素的 active 类
                tabNavs.forEach(function (nav) {
                    nav.classList.remove('active');
                });
                this.classList.add('active');

                // 显示对应的 .gallery_img 和 .gallery_text，隐藏其他
                var galleryImgs = parent.querySelectorAll('.gallery_img');
                var galleryTexts = parent.querySelectorAll('.gallery_text');

                galleryImgs.forEach(function (img, i) {
                    img.style.display = i === index ? 'block' : 'none';
                });

                galleryTexts.forEach(function (text, i) {
                    text.style.display = i === index ? 'block' : 'none';
                });
            });
        });

        // 触发第一个 .tab__nav 的点击事件
        if (tabNavs.length > 0) {
            tabNavs[0].click();
        }








        if (document.getElementById('cir')) {

            document.getElementById('cir').addEventListener('click', function () {
                // 切换 .cir 和 .bg 的 active 类
                var cirElement = this.querySelector('.cir');
                var bgElement = this.querySelector('.bg');
                cirElement.classList.toggle('active');
                bgElement.classList.toggle('active');

                // 切换 .ctrl-img974 .left 和 .ctrl-img974 .right 的显示状态
                var leftElement = document.querySelector('.ctrl-img974 .left');
                var rightElement = document.querySelector('.ctrl-img974 .right');
                leftElement.style.display = leftElement.style.display === 'none' ? 'block' : 'none';
                rightElement.style.display = rightElement.style.display === 'none' ? 'block' : 'none';

                // 切换 #i1 和 #i2 的淡入淡出效果
                fadeToggle(document.getElementById('i1'), 500);
                fadeToggle(document.getElementById('i2'), 500);
            });

        }


    });




    // 自定义 fadeToggle 函数
    function fadeToggle(element, duration) {
        if (window.getComputedStyle(element).opacity === '0') {
            fadeIn(element, duration);
        } else {
            fadeOut(element, duration);
        }
    }

    // 自定义 fadeIn 函数
    function fadeIn(element, duration) {
        element.style.opacity = 0;
        element.style.display = 'block';
        var start = performance.now();
        requestAnimationFrame(function animate(time) {
            var elapsed = time - start;
            var opacity = elapsed / duration;
            if (opacity > 1) opacity = 1;
            element.style.opacity = opacity;
            if (opacity < 1) {
                requestAnimationFrame(animate);
            }
        });
    }

    // 自定义 fadeOut 函数
    function fadeOut(element, duration) {
        element.style.opacity = 1;
        var start = performance.now();
        requestAnimationFrame(function animate(time) {
            var elapsed = time - start;
            var opacity = 1 - elapsed / duration;
            if (opacity < 0) opacity = 0;
            element.style.opacity = opacity;
            if (opacity > 0) {
                requestAnimationFrame(animate);
            } else {
                element.style.display = 'none';
            }
        });
    }



})()